<template>
  <div style="font-size:14px">
    <div class="page-container hide-list-menu">
      <div class="page-content">
        <!-- komponen header halaman-->
        <Header />
      </div>
    </div>

    <div class="container-fluid">
      <a href="#" v-on:click="kasir">Kasir</a> \ Pengaturan
      <div class="row py-5">
          <div class="col-3">
            <a 
                style="color:#000"
                href="#"
                data-toggle="modal"
                data-target="#modalStrukSetting"
               >
              <div class="card0">
                  <div class="text-center green">
                      <svg xmlns="http://www.w3.org/2000/svg" width="46" height="56" fill="currentColor" class="bi bi-receipt" viewBox="0 0 16 16">
                        <path d="M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27zm.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0l-.509-.51z"/>
                        <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5z"/>
                      </svg>
                  </div>
                  <div class="text-center" style="padding-top:30px">
                    <strong>Struk</strong>
                  </div>
                  <div class="text-center">
                      Atur informasi bisnis dan footnotes<br/>
                      dan struk melalui menu ini
                  </div>
              </div>
            </a>
          </div>
          <div class="col-3">
              <a 
                style="color:#000"
                href="#"
                data-toggle="modal"
                data-target="#modalLogoutPerusahaan"
               >
              <div class="card0">
                  <div class="text-center green">
                      <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="currentColor" class="bi bi-box-arrow-left" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0v2z"/>
                        <path fill-rule="evenodd" d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"/>
                      </svg>
                  </div>
                  <div class="text-center" style="padding-top:30px">
                    <strong>Logout</strong>
                  </div>
                  <div class="text-center">
                      Keluar dari <br/>Outlet {{outletName}}
                  </div>
              </div>
              </a>
          </div>
      </div>
    </div>
    <!-- modal konfirm logout perusahaan -->
    <konfirmLogoutPerusahaan />
    <!-- modal setting receipt -->
    <StrukSetting />
  </div> 
</template>
  
<script>
/* eslint-disable */ //disabled eslint compiler
// @ is an alias to /src
import { mapGetters } from "vuex";
import Header from "@/components/Header_c.vue";
import konfirmLogoutPerusahaan from "@/components/konfirmasi_modal/KonfirmLogoutPerusahaan.vue";
import StrukSetting from "@/components/StrukSetting_c.vue";

export default {
  name: "Setting_v",
  components: {
    Header,
    konfirmLogoutPerusahaan,
    StrukSetting,
  },
  methods: {
    kasir(){
      this.$router.push({name: 'Kasir'})
    }
  },
  computed: {
    ...mapGetters(["outletName"]),
  },
  watch: {},
  mounted() {
  },
};
</script>
<style scoped>
.card0 {
  box-shadow: 0px 4px 8px 0px #757575;
  border-radius: 10px;
  padding: 50px 0px 50px 0px;
}

.green {
  background-color: green;
  padding: 10px 5px 0px 0px;
  width: 80px;
  height:80px;
  border-radius: 50%;
  color: #fff;
  margin-left:36%;
};


</style>