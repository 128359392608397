<template>
  <div>
    <!-- Modal setting receipt-->
    <div id="modalStrukSetting" class="modal fade">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title text-bolder">
              Pengaturan informasi bisnis dan footnotes struk
            </h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="strukSetting">
              <div class="row py-2">
                <div class="col-6">
                  <div class="form-group">
                    <label>Nama Bisnis</label>
                    <input
                      type="text"
                      v-model="form.CompanyName"
                      class="form-control"
                      placeholder=""
                    />
                  </div>
                  <div class="form-group">
                    <label>Alamat</label>
                    <textarea
                      v-model="form.CompanyAddress"
                      class="form-control"
                      rows="3"
                    ></textarea>
                  </div>
                  <div class="form-group">
                    <label>No Telepon</label>
                    <input
                      type="text"
                      v-model="form.CompanyPhone"
                      class="form-control"
                      placeholder=""
                    />
                  </div>
                  <hr style="margin-bottom:6px"/>
                  <div class="form-group">
                    <label>Ukuran Struk</label>
                    <select class="form-control" v-model="form.selectedReceiptTemplate">
                      <option value="small">Kecil</option>
                      <option value="big">Besar</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <ul style="padding-left:20px">
                        <li><small>Ukuran Struk Kecil biasa digunakan pada printer thermal</small></li>
                        <li><small>Ukuran Struk Besar biasa digunakan untuk ukuran kertas besar (A4, F4, Legal, dan lainya)</small></li>
                    </ul>
                  </div>
                </div>
                <div class="col-6">
                  <my-upload
                    field="image"
                    @crop-success="cropSuccess"
                    @crop-upload-success="cropUploadSuccess"
                    @crop-upload-fail="cropUploadFail"
                    v-model="show"
                    langType="en"
                    :langExt="langType"
                    :noCircle="dnoCircle"
                    :noRotate="dnoRotate"
                    :width="100"
                    :height="100"
                    imgFormat="png/jpg/jpeg"
                  ></my-upload>
                  <div class="text-center mb-10">
                    <img :src="imgDataUrl" style="width: auto; height: 150px" />
                  </div>
                  <div class="row">
                    <div class="col-lg-10">
                      <button
                        type="button"
                        @click="toggleShow"
                        class="btn btn-green btn-block btn-sm"
                      >
                        Atur Logo pada Struk
                      </button>
                    </div>
                    <div class="col-lg-2">
                      <button
                        type="button"
                        @click="delLogo"
                        class="btn btn-danger btn-block btn-sm"
                        title="hilangkan logo pada struk"
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                    </div>
                  </div>
                  <hr />
                  <div class="form-group mt-10">
                    <label>Footnotes Pada Struk</label>
                    <input
                      type="text"
                      v-model="form.FootNoteSatu"
                      class="form-control"
                      placeholder="Terima kasih atas kunjungan Anda"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      v-model="form.FootNoteDua"
                      class="form-control"
                      placeholder="Buka jam 10:00 - 21:00"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      v-model="form.FootNoteTiga"
                      class="form-control"
                      placeholder="Slogan / Tagline bisnis anda"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      v-model="form.FootNoteEmpat"
                      class="form-control"
                      placeholder="Follow IG @nutapos"
                    />
                  </div>
                </div>
              </div>
              <div class="row py-2">
                <div class="col-6"></div>
                <div class="col-6">
                  <div class="row">
                    <div class="col-6">
                      <button
                        class="btn btn-link btn-block"
                        type="button"
                        data-dismiss="modal"
                      >
                        Batal
                      </button>
                    </div>
                    <div class="col-6">
                      <button type="submit" class="btn btn-green btn-block">
                        Simpan
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import myUpload from "vue-image-crop-upload";
import $ from "jquery";
export default {
  name: "StrukSetting_c",
  data() {
    return {
      show: false,
      dnoCircle: true,
      dnoRotate: false,
      langType: {
        hint: "Klik atau tarik dan lepas file disini",
        loading: "Mengunggah…",
        noSupported:
          "Browser tidak mendukung, silahkan menggunakan IE10+ atau browsers lainnya",
        success: "Unggah file berhasil",
        fail: "Unggah file gagal",
        preview: "Preview",
        btn: {
          off: "Batal",
          close: "Tutup",
          back: "Kembali",
          save: "Simpan",
        },
        error: {
          onlyImg: "Hanya gambar",
          outOfSize: "Gambar melebihi batas ukuran: ",
          lowestPx: "Ukuran gambar terlalu kecil. Diharapkan setidaknya: ",
        },
      },
      imgDataUrl: "", // the datebase64 url of created image
      field: "",
      form: {
        FootNoteSatu: "",
        FootNoteDua: "",
        FootNoteTiga: "",
        FootNoteEmpat: "",

        CompanyName: "",
        CompanyAddress: "",
        CompanyPhone: "",
        selectedReceiptTemplate: "small",
      },
    };
  },
  components: {
    "my-upload": myUpload,
  },
  watch: {},
  computed: {
    ...mapGetters(["options", "outletID", "wsBaseUrl", "connectionStatus"]),
  },
  methods: {
    ...mapActions(["actSetStruk", "actSaveImgLocalStorage"]),
    //delete logo
    delLogo() {
      this.imgDataUrl = "";
      var sentData = {
        delLogo: true,
      };
      this.$store.dispatch("actDelLogoStruk", sentData);
    },
    //set data to form
    setDataStruk() {
      if (this.options) {
        this.form.FootNoteSatu = this.options[0].FootNoteSatu;
        this.form.FootNoteDua = this.options[0].FootNoteDua;
        this.form.FootNoteTiga = this.options[0].FootNoteTiga;
        this.form.FootNoteEmpat = this.options[0].FootNoteEmpat;

        this.form.CompanyName = this.options[0].CompanyName;
        this.form.CompanyAddress = this.options[0].CompanyAddress;
        this.form.CompanyPhone = this.options[0].CompanyPhone;

        if (this.options[0].OnlineImagePath) {
          if (this.connectionStatus == "Online") {
            if(this.options[0].OnlineImagePath.substring(-1, 4) == "http") {
              this.imgDataUrl = this.options[0].OnlineImagePath
            } else {
              this.imgDataUrl = this.wsBaseUrl + this.options[0].OnlineImagePath;
            }
          } else {
            this.setDataLogoOffline();
          }
        } else {
          this.setDataLogoOffline();
        }
        //console.log(this.imgDataUrl)
      }
      var templateData = localStorage.getItem("receipttemplate") ? JSON.parse(localStorage.getItem("receipttemplate")) : [];
      if(templateData.length > 0) {
        this.form.selectedReceiptTemplate = templateData[0].template
      } else {
        this.form.selectedReceiptTemplate = "small"
      }
    },
    //set data logo to form if offline
    setDataLogoOffline() {
      var lImage = [];
      var filterImage = [];
      lImage = localStorage.getItem("localimage")
        ? JSON.parse(localStorage.getItem("localimage"))
        : [];
      if (lImage.length > 0) {
        filterImage = lImage.filter((sImage) => {
          return sImage.Table == "options" && sImage.TableID == "1";
        });
        if (filterImage.length > 0) {
          this.imgDataUrl = filterImage[0].Image;
        }
      }
    },
    //save data informasi bisnis dan footnotes
    strukSetting() {
      this.$store.dispatch("actSetStruk", this.form);
      $("#modalStrukSetting").modal("hide");
    },
    toggleShow() {
      this.show = !this.show;
    },
    /**
     * crop success
     *
     * [param] imgDataUrl
     * [param] field
     */
    cropSuccess(imgDataUrl, field) {
      console.log("-------- crop success --------");
      this.imgDataUrl = imgDataUrl;
      this.field = field;
      var dataSend = null;
      if (this.connectionStatus == "Online") {
        var ext = imgDataUrl.split(";")[0].split("/")[1];
        var image = imgDataUrl.split("base64,")[1];
        $.ajax({
          url: this.wsBaseUrl + "ws/synlogo",
          data: {
            id: "1",
            ext: ext,
            devid: this.outletID,
            varian: "Nuta",
            image: image,
          },
          method: "POST",
          success: (data) => {
            if (data.status == "OK") {
              this.$store.dispatch("downloadoptions");
              dataSend = {
                table: "options",
                tableid: "1",
                image: imgDataUrl,
                synmode: 0,
              };
            } else {
              dataSend = {
                table: "options",
                tableid: "1",
                image: imgDataUrl,
                synmode: 1,
              };
            }
            this.$store.dispatch("actSaveImgLocalStorage", dataSend);
          },
          error: (error) => {
            console.log(error);
          },
        });
      } else {
        dataSend = {
          table: "options",
          tableid: "1",
          image: imgDataUrl,
          synmode: 1,
        };
        this.$store.dispatch("actSaveImgLocalStorage", dataSend);
      }
    },
    /**
     * upload success
     *
     * [param] jsonData  server api return data, already json encode
     * [param] field
     */
    cropUploadSuccess(jsonData, field) {
      console.log("-------- upload success --------");
      console.log(jsonData);
      console.log("field: " + field);
    },
    /**
     * upload fail
     *
     * [param] status    server api return error status, like 500
     * [param] field
     */
    cropUploadFail(status, field) {
      console.log("-------- upload fail --------");
      console.log(status);
      console.log("field: " + field);
    },
  },
  mounted() {
    this.setDataStruk();
  },
};
</script>
<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #00ae2b;
}

input:focus + .slider {
  box-shadow: 0 0 1px #00ae2b;
}

input:checked + .slider:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>