<template>
  <!-- Modal konfirmasi hapus data cart-->
  <div id="modalLogoutPerusahaan" class="modal fade">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title text-bolder">Konfirmasi</h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row py-2">
            <div class="col-12">
              <p style="text-align: left">
                Apakah Anda yakin untuk keluar dari akun Nutacloud Outlet {{outletName}} ?
              </p>
            </div>
          </div>
          <div class="row py-2">
            <div class="col-6"></div>
            <div class="col-6">
              <div class="row">
                <div class="col-6">
                  <button
                    class="btn btn-link btn-block"
                    type="button"
                    data-dismiss="modal"
                  >
                    Batal
                  </button>
                </div>
                <div class="col-6">
                  <button
                    type="button"
                    @click="logoutperusahaan"
                    class="btn btn-green btn-block"
                  >
                    Ya
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters,mapActions } from "vuex";
import $ from "jquery";
export default {
  name: "KonfirmLogoutPerusahaan",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["outletName"]),
  },
  mounted() {},
  methods: {
    ...mapActions(["logoutClearAllData"]),
    logoutperusahaan(){
      this.$store.dispatch("logoutClearAllData");
      $("#modalLogoutPerusahaan").modal("hide");
    }
  },
};
</script>
<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #00ae2b;
}

input:focus + .slider {
  box-shadow: 0 0 1px #00ae2b;
}

input:checked + .slider:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>